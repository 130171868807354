import * as React from 'react';
import TwitterIcon from '@material-ui/icons/Twitter';
import SoundcloudIcon from '../icons/Soundcloud';
import SteamIcon from '../icons/Steam';

const IconsL = (props: any) => {
  return (
    <div className="iconsLeft">
      <a href="https://soundcloud.com/barry-russell-1"><SoundcloudIcon color="primary" className="icon" fontSize="large"/></a>
      <a href="https://steamcommunity.com/id/tiniwraith/"><SteamIcon color="primary" className="icon" fontSize="large"/></a>
      <a href="https://twitter.com/tiniwraith"><TwitterIcon color="primary" className="icon" fontSize="large"/></a>
    </div>
  );
};

export default IconsL