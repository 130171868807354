import * as React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import './contactBtn.css'

const ContactBtn = withStyles({
  root: {
    marginBottom: '1em'
  },
})(Button);

export default function ClassesShorthand(){
  return <ContactBtn variant="contained" color="primary"><a href="mailto:blrussell09@gmail.com" className="emailBtn">Contact Me</a></ContactBtn>;
}