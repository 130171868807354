import * as React from 'react';
import GitHubIcon from '@material-ui/icons/GitHub';
import LinkedInIcon from '@material-ui/icons/LinkedIn';

const IconsR = (props: any) => {
  return (
    <div className="iconsRight">
      <a href="https://www.linkedin.com/in/barry-russelljr/"><LinkedInIcon color="primary" className="icon" fontSize="large"/></a>
      <a href="https://github.com/BLRussell-09"><GitHubIcon color="primary" className="icon" fontSize="large"/></a>
    </div>
  );
};

export default IconsR;