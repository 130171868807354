import * as React from 'react';
import './AboutMe.css';
import CareerTimeline from '../careerTimeline/CareerTimeline';

const AboutMe = () => {
  return (
    <div className="aboutMe">
      <div className="face_icon">
        <img src="https://s3.amazonaws.com/brussellnet.homepage.images/crop2.jpg" alt="Barry Russell's face. He looks smug." className="faceCon"/>
      </div>
      <div className="aboutMeBlurb">
        <h2 className="aboutMeName">Barry Russell</h2>
        <p className="aboutMeText">Graduate of Nashville Software School Evening Cohort 7, with 2 years of experience as a developer. </p>
      </div>
      <div className="timeline">
        <CareerTimeline />
      </div>
    </div>
  );
};

export default AboutMe