import * as React from 'react';
import './Titleplate.css';

export interface Props {
  title: string;
  company: string;
  companySite: string;
}

class Titleplate extends React.Component<Props, object> {
  render() {

    const {title, company, companySite} = this.props;

    return (
      <div className="titleplate">
        <p className="brackets">&#123;</p>
        <p className="title">{title}</p>
        <p className="brackets">&#125;</p>
        <p>@</p>
        <a href={companySite} className="company">{company}</a>
      </div>
    );
  }
};

export default Titleplate;
