import * as React from 'react';
import './App.css';
import Faceplate from './components/faceplate/Faceplate';
import AboutMe from './components/aboutMe/AboutMe';
import Portfolio from './components/portfolio/Portfolio';

class App extends React.Component {

  render() {
    return(
      <div className="app">
        <Faceplate/>
        <AboutMe/>
        <Portfolio/>
      </div>
    );
  }
};

export default App;
