import axios from 'axios'

const getBadges = () => {
  return new Promise ((resolve, reject) => {
    axios(`https://teamtreehouse.com/barryrussell.json`).then((res) => {
      resolve(res.data.badges);
    }).catch((err) => {
      reject(err);
    });
  });
};

export default getBadges