import React, {Component} from 'react';
import './Portfolio.css';
import requests from './requests';

class Portfolio extends Component {

  state = {
    badges: []
  }

  componentDidMount() {
    console.log('Mounted!');
    requests().then((res) => {
      console.log(res);
      this.setState({
        badges: res
      })
    })
  }

  render() {
    
    const badgeComponent = this.state.badges.map((badge) => {
      return (
        <a href={badge['url']}><img src={badge['icon_url']} alt={badge['name']} className="achievements"/></a>
      )
    })

    return (
      <div className="portfolio">
        <div className="portTitle">
          <h3>Portfolio</h3>
        </div>
        <div className="projects">
          <a href="#">IRA Calc</a>
          <a href="#">Comic Lib</a>
          <a href="#">10 Day Loan Calc</a>
        </div>
        <div className="learnTitle">
          <h3>What I've learned</h3>
        </div>
        <div className="learn">
          {badgeComponent}
        </div>
      </div>
    );
  }
}

export default Portfolio;