import * as React from 'react';
import './Faceplate.css';
import IconsL from '../iconsL/IconsL';
import IconsR from '../iconsR/iconsR';
import Titleplate from '../titleplate/Titleplate';
import Button from '@material-ui/core/Button';
import ContactBtn from '../contactBtn/contactBtn';

const Faceplate = () => {
  return (
    <div className="header">
      <div className="facebar">
        <img src="https://s3.amazonaws.com/brussellnet.homepage.images/crop.jpg" alt="Barry Russell's face. He looks smug." className="face"/>
        <Titleplate title="Software Engineer I" company="Fortera Credit Union" companySite="https://www.forteracu.com/"/>
      </div>
      <div className="namebar">
        <IconsR/>
        <p className="name">Barry Russell</p>
        <IconsL/>
      </div>
      <div className="contactMe">
        <ContactBtn/>
      </div>
    </div>
  );
};

export default Faceplate;